import React from "react";
import pdfimg from "../../../assests/images/others/Group 1778.png";
import pdf1 from "../../../assests/images/pdf/2nd Moot Court Competition-Quest Indicia- 2024.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import pdf2 from "../../../assests/images/others/Notice.pdf";
import pdf3 from "../../../assests/images/others/Notice2.pdf";
import pdf4 from "../../../assests/images/others/Notice4.pdf";
import pdf5 from "../../../assests/images/others/PTM Notice.pdf";
import pdf6 from "../../../assests/images/others/Notice_Workshop_financial.pdf";
import pdf7 from "../../../assests/images/others/Notice- Academic Excellence Award Presentation Ceremony.jpg";
import pdf8 from "../../../assests/images/others/Notice- Celebration of National Librarian’s Day, 2024.pdf";
import pdf9 from "../../../assests/images/others/Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024.pdf";
import pdf10 from "../../../assests/images/others/Notice- workshop new cr. law.pdf";
import pdf11 from "../../../assests/images/others/NOTICE - SOFT SKILLS SESSION.pdf";
import pdf12 from "../../../assests/images/others/Notice_Avishkar Competition, 2024..pdf";
import pdf13 from "../../../assests/images/others/role of forensic science in investigation of crime.jpg";

export default function Notice() {
  const noticeData = [
    // {
    //   notice: "'Quest Indicia' 2024-2nd Moot Court Competition.",
    //   link: pdf1,
    // },
    {
      id: 1,
      notice:
        "Notice- Symposium On 05.05.24- Symposium on Pathways to Success for Aspiring Lawyers in the Contemporary Era",
      link: pdf2,
    },
    {
      id: 2,
      notice:
        " Notice- Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 10-11 May 2024",
      link: pdf3,
    },
    {
      id: 3,
      notice:
        " Notice-  Filling Examination Form of April- May 2024 Examination",
      link: pdf4,
    },
    {
      id: 4,
      notice: " Notice- Academic Excellence Award Presentation Ceremony",
      link: pdf7,
    },
    {
      id: 5,
      notice: " Notice- Parent's Teacher's Meeting- 29 June 2024",
      link: pdf5,
    },
    {
      id: 6,
      notice: " Notice- Workshop on Financial Planning- 03 July 2024",
      link: pdf6,
    },
    {
      id: 7,
      notice: "Notice- Celebration of National Librarian’s Day, 2024 ",
      link: pdf8,
    },
    {
      id: 8,
      notice:
        "Notice- Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024 ",
      link: pdf9,
    },
    {
      id: 9,
      notice: "Notice- Workshop on Recent Criminal Law Legislations ",
      link: pdf10,
    },
    {
      id: 10,
      notice: "NOTICE - SOFT SKILLS SESSION",
      link: pdf11,
    },
    {
      id: 11,
      notice: "NOTICE - Avishkar Competition, 2024 ",
      link: pdf12,
    },
    {
      id: 12,
      notice: "Registration form: Avishkar Competition, 2024 ",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScBqIriVXeth7ws67-1Lr5rn9c4nyzuIC3AEBKG5GaXSem7Cw/viewform ",
    },
    {
      id: 13,
      notice: "Present Guest Lecture on Role Of Forensic Science In Investigaation Of Crime ",
      link: pdf13 ,
    },
  ];
  noticeData.sort((a, b) => b.id - a.id);

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div className="headerdiv2">
        <img src={pdfimg} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Notice</h2>
          <Link to="/notices" className="linerm">
            {" "}
            <p className="mb-0">
              All Notice{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event p-3">
        {noticeData.map((notice, index) => (
          <p className="notice py-2" key={index}>
            <a
              href={notice.link}
              onClick={() => handleDownload(notice.link)}
              className="linerm"
              target="_blank"
            >
              {notice.notice}{" "}
              <span className="mx-1">
                <Badge bg="danger">New</Badge>
              </span>
            </a>
            {notice.btn && <span className="notice-btn">{notice.btn}</span>}
          </p>
        ))}
      </div>
    </div>
  );
}
