import React from "react";
import { Link } from "react-router-dom";
// import pdf1 from "../../assests/images/pdf/2nd Moot Court Competition-Quest Indicia- 2024.pdf";
import { Badge } from "react-bootstrap";
import pdf2 from "../../assests/images/others/Notice.pdf";
import pdf3 from "../../assests/images/others/Notice2.pdf";
import pdf4 from "../../assests/images/others/Notice4.pdf";
import pdf5 from "../../assests/images/others/PTM Notice.pdf";
import pdf6 from "../../assests/images/others/Notice_Workshop_financial.pdf";
import pdf7 from "../../assests/images/others/Notice- Celebration of National Librarian’s Day, 2024.pdf";
import pdf8 from "../../assests/images/others/Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024.pdf";
import pdf9 from "../../assests/images/others/Notice- workshop new cr. law.pdf";
import pdf10 from "../../assests/images/others/NOTICE - SOFT SKILLS SESSION.pdf";
import pdf11 from "../../assests/images/others/Notice_Avishkar Competition, 2024..pdf";
import pdf13 from "../../assests/images/others/role of forensic science in investigation of crime.jpg";
export default function Notices() {
  const NoticeData = [
    // {
    //   id: 1,
    //   title: "Quest Indicia' 2024-2nd Moot Court Competition.",
    //   link: pdf1,

    //   // date: "01 Feb 2024",
    // },
    {
      id: 2,
      title: "Notice- Symposium On 05.05.24",
      link: pdf2,
      // date: "01 Feb 2024",
    },
    {
      id: 3,
      title:
        " Notice- Field Marshal SHFJ Manekshaw 2nd Humanitarian Law Symposium 10-11 May 2024",
      link: pdf3,
      // date: "01 Feb 2024",
    },
    {
      id: 4,
      title: " Notice- Filling Examination Form of April- May 2024 Examination",
      link: pdf4,
      // date: "01 Feb 2024",
    },
    {
      id: 5,
      title: " Notice- Parent's Teacher's Meeting- 29 June 2024",
      link: pdf5,
      // date: "01 Feb 2024",
    },
    {
      id: 6,
      title: "Notice- Workshop on Financial Planning- 03 July 2024",
      link: pdf6,
      // date: "01 Feb 2024",
    },
    {
      id: 7,
      title: " Notice- Celebration of National Librarian’s Day, 2024",
      link: pdf7,
      // date: "01 Feb 2024",
    },
    {
      id: 8,
      title:
        "Notice- Orientation Programme 2024- First Year B.B.A. & B.A. LL.B 2024- 25 On 20-21 August 2024",
      link: pdf8,
      // date: "01 Feb 2024",
    },
    {
      id: 9,
      title: "Notice- Workshop on Recent Criminal Law Legislations ",
      link: pdf9,
    },
    {
      id: 10,
      title: "NOTICE - SOFT SKILLS SESSION",
      link: pdf10,
    },
    {
      id: 11,
      title: "NOTICE - Avishkar Competition, 2024 ",
      link: pdf11,
    },
    {
      id: 12,
      title: "Registration form: Avishkar Competition, 2024 ",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScBqIriVXeth7ws67-1Lr5rn9c4nyzuIC3AEBKG5GaXSem7Cw/viewform ",
    },
    {
      id: 13,
      title:
        "Present Guest Lecture on Role Of Forensic Science In Investigaation Of Crime ",
      link: pdf13,
    },
  ];

  NoticeData.sort((a, b) => b.id - a.id);

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {NoticeData.map((data, i) => (
        <div className="row  d-flex align-items-center flex-wrap " key={i}>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <p className="ntname progtext m-0">
              {data.title}{" "}
              <span className="mx-1">
                <Badge bg="danger">New</Badge>
              </span>
            </p>

            <a href={data.link} className="linerm" target="_blank">
              <p className="ntdate">
                {data.date} <span className="mx-1"></span>
              </p>
            </a>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <p
              className="event-btn"
              style={{ cursor: "pointer" }}
              onClick={() => handleDownload(data.link)}
            >
              <span className="view-btn">View</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
